import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import './global.css';

import * as styles from './landing.module.css';

import Layout from '../components/layout/layout';
import BinarySystem from '../components/binarySystem';

const IndexPage = () => {
	const { bio, cv, title } = useStaticQuery(graphql`
		{
			contentfulHomePage {
				title
				bio {
					childMarkdownRemark {
						html
					}
				}
				cv {
					file {
						url
					}
				}
			}
		}
	`).contentfulHomePage;

	return (
		<Layout>
			<div className={styles.hero}>
				<div className={styles.content}>
					<BinarySystem />
					<div className={styles.textContent}>
						<div className={styles.bio}>
							<h1>{title}</h1>
							<div
								dangerouslySetInnerHTML={{
									__html: bio.childMarkdownRemark.html,
								}}
							></div>
						</div>
						<div className={styles.buttons}>
							{/* TODO: Uncomment this once page is created */}
							{/* <a href='/reasearch'>See my Research</a>*/}
							<a href={cv.file.url} target='blank'>
								Read My CV
							</a>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;
