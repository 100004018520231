import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as styles from './binarySystem.module.css';

const BinarySystem = () => {
	const { headshot, title } = useStaticQuery(graphql`
		{
			contentfulHomePage {
				title
				headshot {
					file {
						url
					}
				}
			}
		}
	`).contentfulHomePage;
	return (
		<div className={styles.binarySystem}>
			<div className={styles.yellowGiant}>
				<img src={headshot.file.url} alt={title} />
			</div>

			<div className={styles.blueGiant}></div>
		</div>
	);
};

export default BinarySystem;
